import { createSlice } from "@reduxjs/toolkit";

export const transactionsReducer = createSlice({
    name: 'transactions',
    initialState: {
        transactions: [],
        personalTransactions: [],
    },
    reducers: {
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setPersonalTransactions: (state, action) => {
            state.personalTransactions = action.payload;
        },
    },
});

export const {setTransactions} = transactionsReducer.actions;
export const {setPersonalTransactions} = transactionsReducer.actions;

export default transactionsReducer.reducer;
