import { createSlice } from "@reduxjs/toolkit";

export const tagsReducer = createSlice({
    name: 'tags',
    initialState: {
        tags: [],
    },
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload;
        }
    },
});

export const {setTags} = tagsReducer.actions;

export default tagsReducer.reducer;
