import React from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmationModal = (props) => {
    return (
        <Modal show={props.show} onHide={() => { props.handleClose() }} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    <p>{props.message}</p>
                    <div className="d-flex justify-content-end">
                        <Button variant="default" onClick={() => { props.handleClose() }}>Cancel</Button>
                        <Button variant="primary" onClick={() => { props.handleConfirm() }}>Confirm</Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired
}

export default ConfirmationModal;
