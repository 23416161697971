import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import API from '../lib/API';


const TransactionFormModal = (props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [ticker, setTicker] = useState(props.transaction ? props.transaction.ticker : '');
    const [price, setPrice] = useState(props.transaction ? props.transaction.price : '');
    const [quantity, setQuantity] = useState(props.transaction ? props.transaction.quantity : '');
    const [date, setDate] = useState(props.transaction ? props.transaction.date : '');
    const [type, setType] = useState(props.transaction ? props.transaction.type : 'BUY');
    const [portfolio, setPortfolio] = useState(props.transaction ? (props.transaction.portfolio ? props.transaction.portfolio : '') : '');
    const [note, setNote] = useState(props.transaction ? (props.transaction.note ? props.transaction.note : '') : '');
    const token = useSelector(state => state.status.token);
    const userID = useSelector(state => state.status.userID);

    const submit = (event) => {
        event.preventDefault();

        if (isNaN(price) || isNaN(quantity)) {
            setErrorMessage('Price and quantity have to be numbers');
        }

        if (!(ticker && date && type)) {
            setErrorMessage('Ticker, date and type are required');
        }

        if (props.transaction) {
            API.updateStockTransaction(token, props.transaction.id, userID, ticker, Number(price).toFixed(2), Number(quantity), date, Number(portfolio), note).then(response => {
                props.handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error updating stock transaction');
            });
        } else {
            API.createStockTransaction(token, userID, ticker, Number(price).toFixed(2), Number(quantity), date, type, Number(portfolio), note).then(response => {
                props.handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error creating stock transaction');
            });
        }
    };

    const deleteStockTransaction = () => {
        if (!props.transaction) {
            return;
        }

        API.deleteStockTransaction(token, props.transaction.id).then(response => {
            props.handleClose();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error deleting stock transaction');
        })
    }
    
    return (
        <Modal show={props.show} onHide={() => { props.handleClose() }} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.transaction ? 'Edit transaction' : 'New transaction'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticker</Form.Label>
                            <Form.Control type="text" placeholder="Ticker" value={ticker} onChange={e => { setTicker(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select value={type} onChange={e => { setType(e.target.value) }}>
                                <option value="BUY">BUY</option>
                                <option value="SELL">SELL</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price per share</Form.Label>
                            <Form.Control type="number" placeholder="Price" value={price} onChange={e => { setPrice(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" placeholder="Quantity" value={quantity} onChange={e => { setQuantity(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="text" placeholder="YYYY-MM-DD" value={date} onChange={e => { setDate(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Portfolio</Form.Label>
                            <Form.Select value={portfolio} onChange={e => { setPortfolio(e.target.value) }}>
                                <option value="">None</option>
                                {props.portfolios ? props.portfolios.map(p => <option key={p.id} value={p.id}>{p.name}</option>) : null}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Note</Form.Label>
                            <Form.Control type="text" placeholder="Note" value={note} onChange={e => {setNote(e.target.value)}} />
                        </Form.Group>
                        <>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            {props.transaction ? (
                                <Button variant="danger" onClick={() => {deleteStockTransaction()}}>Delete</Button>
                            ) : null}
                        </>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
};

TransactionFormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    transaction: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    portfolios: PropTypes.array.isRequired 
}

export default TransactionFormModal;
