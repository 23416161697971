import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from '../lib/API';

import { setCompaniesList, setCurrentCompany } from '../slices/companiesSlice';

import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import NavBar, { tabs } from '../components/MainNavBar';
import CompanyDetailsModal from '../components/CompanyDetailsModal';

const HomeView = () => {

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const companies = useSelector(state => state.companies.companiesList);
    const company = useSelector(state => state.companies.currentCompany);
    const dispatch = useDispatch();
    const emptyFilter = {
        'ticker': '',
        'name': '',
        'sector': '',
        'applied': false,
    };
    const [filter, setFilter] = useState(emptyFilter);
    const [displayCompanies, setDisplayCompanies] = useState([]);

    const fetchCompanies = useCallback(() => {
        API.getCompanies().then(response => {
            dispatch(setCompaniesList(response));
            setLoading(false);
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Unable to fetch companies');
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const filterCompanies = () => {
        const ticker = filter['ticker'];
        const name = filter['name'];
        const sector = filter['sector'];
        let listToDisplay = [];

        // If there is a filter, apply it and set the list of results to display
        if (ticker || name || sector) {
            // Filter the list of companies and set displayCompanies
            listToDisplay = companies.filter(stock =>
                (ticker ? stock.ticker.toLowerCase().includes(ticker.toLowerCase()) : true)
                && (name ? stock.name.toLowerCase().includes(name.toLowerCase()) : true)
                && (sector ? (stock.sector ? stock.sector.toLowerCase().includes(sector.toLowerCase()) : false) : true)
            );
        }

        setFilter(Object.assign({}, filter, { 'applied': true }));
        setDisplayCompanies(listToDisplay);
    }

    return (
        <>
            <NavBar tab={tabs.home} />
            {loading ? (
                <div>
                    <h3>Loading companies</h3>
                    <Spinner variant='primary' animation='border' />
                </div>
            ) : (
                <Container>
                    <h2 className="mt-5 text-center">List of tracked companies</h2>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <CompanyDetailsModal show={company != null} company={company} />
                    <Form>
                        <Row className="mt-3">
                            <Col md={4}>
                                <Form.Control type="text" placeholder="Ticker" value={filter['ticker']} onChange={e => setFilter(Object.assign({}, filter, { 'ticker': e.target.value }))} />
                            </Col>
                            <Col md={4}>
                                <Form.Control type="text" placeholder="Name" value={filter['name']} onChange={e => setFilter(Object.assign({}, filter, { 'name': e.target.value }))} />
                            </Col>
                            <Col md={4}>
                                <Form.Control type="text" placeholder="Sector" value={filter['sector']} onChange={e => setFilter(Object.assign({}, filter, { 'sector': e.target.value }))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <Button onClick={filterCompanies} className="float-end">Apply Filter</Button>
                            </Col>
                            <Col md={6}>
                                <Button onClick={() => { setDisplayCompanies([]); setFilter(emptyFilter) }} className="float-start">Clear filter</Button>
                            </Col>
                        </Row>
                    </Form>
                    {(filter['ticker'] || filter['name'] || filter['sector']) && filter['applied'] && !displayCompanies.length ? (
                        <Alert variant='warning'>No results for search</Alert>
                    ) : null}
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Ticker</th>
                                <th>Name</th>
                                <th>Industry</th>
                                <th>Sector</th>
                                <th>Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(displayCompanies.length ? displayCompanies : companies).map(stock => {
                                return (
                                    <tr key={stock.ticker} onClick={() => { dispatch(setCurrentCompany(stock)) }} style={{ cursor: 'pointer' }}>
                                        <td>{stock.ticker}</td>
                                        <td>{stock.name}</td>
                                        <td>{stock?.industry}</td>
                                        <td>{stock?.sector}</td>
                                        <td>{stock.website ? (<a href={stock.website}>{stock.website}</a>) : null}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Container>
            )}
        </>
    );
};

export default HomeView;
