import {createSlice} from "@reduxjs/toolkit";

export const companiesReducer = createSlice({
    name: 'companies',
    initialState: {
        companiesList: [],
        ipoList: [],
        currentCompany: null,
    },
    reducers: {
        setCompaniesList: (state, action) => {
            state.companiesList = action.payload;
        },
        setIPOList: (state, action) => {
            state.ipoList = action.payload;
        },
        setCurrentCompany: (state, action) => {
            state.currentCompany = action.payload;
        }
    }
});

export const {setCompaniesList, setIPOList, setCurrentCompany} = companiesReducer.actions;

export default companiesReducer.reducer;
