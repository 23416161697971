import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import companiesReducer from './slices/companiesSlice';
import watchedStocksReducer from './slices/watchedStocksSlice';
import statusReducer from './slices/statusSlice';
import portfoliosReducer from './slices/portfoliosSlice';
import transactionsReducer from './slices/transactionsSlice';
import stocksReducer from './slices/stocksSlice';
import tagsReducer from './slices/tagsSlice';

const store = configureStore({
    reducer: {
        companies: companiesReducer,
        watchedStocks: watchedStocksReducer,
        status: statusReducer,
        portfolios: portfoliosReducer,
        transactions: transactionsReducer,
        stocks: stocksReducer,
        tags: tagsReducer,
    }
});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
