import {createSlice} from "@reduxjs/toolkit";

export const statusReducer = createSlice({
    name: 'status',
    initialState: {
        showLoginDialog: false,
        token: '',
        userID: null,
    },
    reducers: {
        setShowLoginDialog: (state, action) => {
            state.showLoginDialog = action.payload;
        },
        setLoginInfo: (state, action) => {
            state.token = action.payload.token;
            state.userID = action.payload.userID;
        }
    }
});

export const {setShowLoginDialog, setLoginInfo} = statusReducer.actions;

export default statusReducer.reducer;
