import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { setActivePortfolio, setPortfolios } from '../slices/portfoliosSlice';
import { setTransactions } from '../slices/transactionsSlice';
import { setCurrentValues } from '../slices/stocksSlice';
import API from '../lib/API';

import NavBar, { tabs } from '../components/MainNavBar';
import PortfolioFormModal from '../components/PortfolioFormModal';
import TransactionFormModal from '../components/TransactionFormModal';
import PortfolioSection from '../components/ProtfolioSection';


const PortfolioView = () => {
    const dispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(false);
    const [loadingPortfolios, setLoadingPortfolios] = useState(true);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [loadingCurrentValues, setLoadingCurrentValues] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPortfolioModal, setShowPortfolioModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const activePortfolio = useSelector(state => state.portfolios.activePortfolio);
    const [openTransaction, setOpenTransaction] = useState(null);
    const portfolios = useSelector(state => state.portfolios.portfolios);
    const transactions = useSelector(state => state.transactions.transactions);
    const currentValues = useSelector(state => state.stocks.currentValues);
    const token = useSelector(state => state.status.token);

    const updateActivePortfolio = portfolioID => {
        const numberID = Number(portfolioID);
        let foundPortfolio = portfolios ? portfolios.find(p => p.id === numberID) : {};
        if (foundPortfolio === activePortfolio) {
            return;
        }

        if (foundPortfolio === undefined) {
            foundPortfolio = {};
        }
        dispatch(setActivePortfolio(foundPortfolio));
    };

    const getPortfolioTransactions = () => {
        if (!activePortfolio?.id) {
            return transactions;
        }
        return transactions ? transactions.filter(transaction => transaction.portfolio === activePortfolio.id) : [];
    };

    const fetchPortfolios = () => {
        API.getStockPortfolios(token).then(response => {
            dispatch(setPortfolios(response));
            setLoadingPortfolios(false);
            if (initialLoad) {
                updateActivePortfolio(activePortfolio?.id ? activePortfolio.id : "-1");
            }
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Unable to load portfolios');
            setLoadingPortfolios(false);
        });
    };

    const fetchTransactions = () => {
        API.getStockTransactions(token).then(response => {
            dispatch(setTransactions(response));
            setLoadingTransactions(false);
            if (initialLoad) {
                updateActivePortfolio(activePortfolio?.id ? activePortfolio.id : "-1");
            }
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Unable to load transactions');
            setLoadingTransactions(false);
        });
    };

    const fetchCurrentValues = () => {
        API.getStockCurrentValues(token).then(response => {
            let currentValues = {};
            response.forEach(value => {
                currentValues[value.ticker] = value.close;
            });
            dispatch(setCurrentValues(currentValues));
            setLoadingCurrentValues(false);
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Unable to fetch current values');
            setLoadingCurrentValues(false);
        })
    }

    const handlePortfolioModalClose = () => {
        setShowPortfolioModal(false);
        fetchPortfolios();
    }

    const handleTransactionModalClose = () => {
        setShowTransactionModal(false);
        setOpenTransaction(null);
        fetchTransactions();
    }

    useEffect(() => {
        fetchPortfolios();
        fetchTransactions();
        fetchCurrentValues();
    }, []);

    if (!loadingTransactions && !loadingPortfolios && !initialLoad) {
        setInitialLoad(true);
        updateActivePortfolio("-1");
    }

    return (
        <>
            <NavBar tab={tabs.portfolio} />
            {loadingPortfolios && loadingTransactions ? (
                <div>
                    <h3>Loading</h3>
                    <Spinner variant='primary' animation='border' />
                </div>
            ) : (
                <Container>
                    {loadingPortfolios ? (
                        <div>
                            <h3>Loading portfolios</h3>
                            <Spinner variant='primary' animation='border' />
                        </div>
                    ) : (
                        <>
                            <h2>{activePortfolio?.id ? activePortfolio.name : "Overall"} Portfolio</h2>
                            {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                            {showPortfolioModal ? <PortfolioFormModal show portfolio={activePortfolio.id ? activePortfolio : null} handleClose={handlePortfolioModalClose} /> : null}
                            {showTransactionModal || openTransaction !== null ? <TransactionFormModal show portfolios={portfolios} transaction={openTransaction ? openTransaction : null} handleClose={handleTransactionModalClose} /> : null}
                            <div className="mb-2">
                                <Form.Select value={activePortfolio.id} onChange={e => { updateActivePortfolio(e.target.value) }}>
                                    <option key={0} value="-1">All portfolios</option>
                                    {portfolios ? portfolios.map((portfolio) => <option key={portfolio.id} value={portfolio.id}>{portfolio.name}</option>) : null}
                                </Form.Select>
                            </div>
                            <div className="mb-2">
                                <Button className="mr-2" onClick={() => { setShowPortfolioModal(true) }}>{activePortfolio?.id ? 'Edit portfolio' : 'New portfolio'}</Button>
                                <Button className="mx-2" onClick={() => { setShowTransactionModal(true) }}>New Transaction</Button>
                            </div>
                        </>
                    )}
                    {loadingTransactions || loadingPortfolios || loadingCurrentValues ? (
                        <div>
                            <h3>Loading data</h3>
                            <Spinner variant='primary' animation='border' />
                        </div>
                    ) : (
                        <PortfolioSection
                            setOpenTransaction={setOpenTransaction}
                            transactions={getPortfolioTransactions()}
                            portfolio={activePortfolio}
                            currentValues={currentValues}
                        />
                    )}    
                </Container>
            )}
        </>
    );
}

export default PortfolioView;
