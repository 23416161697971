import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {setCurrentCompany} from '../slices/companiesSlice';


const CompanyDetailsModal = (props) => {

    const dispatch = useDispatch();

    if (props.company === null || props.company === undefined) {
        return null;
    }

    const keys = ['ticker', 'name', 'industry', 'sector', 'description', 'website', 'marketCap'];
    const ipoKeys = ['offerDate', 'offerPrice'];
    const additionalDataKeys = Object.keys(props.company.additionalData ? props.company.additionalData : {});

    const isIPO = props.company.ipo ? Object.keys(props.company.ipo).length > 0 : false;

    const renderDetails = () => {
        return (
            <div>
                {keys.map(key => {
                    return <p key={key + 'i'}><span><b>{key}:</b></span> <span>{props.company[key]}</span></p>
                })}
            </div>
        )
    };

    const renderAdditionalData = () => {
        if (additionalDataKeys.length <= 0) {
            return null;
        }

        return (
            <div>
                <hr/>
                <h3>Data dump</h3>

                {additionalDataKeys.map(key => {
                    return <p key={key + 'add'}><span><b>{key}:</b></span> <span>{props.company.additionalData[key]}</span></p>
                })}
            </div>
        )
    }

    const renderIPODetails = () => {
        if (!isIPO) {
            return null;
        }

        return (
            <div>
                <h3>IPO Data</h3>
                {ipoKeys.map(key => {
                    return <p key={key + 'ipo'}><span><b>{key}</b></span> <br/> <span>{props.company.ipo[key]}</span></p>
                })}
            </div>
        )
    };

    const handleClose = () => {
        dispatch(setCurrentCompany(null));
    }

    return (
        <Modal show={props.show} onHide={() => {handleClose()}} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.company.ticker}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    <Row>
                        <Col md={9}>
                            {renderDetails()}
                            {renderAdditionalData()}
                        </Col>
                        <Col md={3}>
                            {renderIPODetails()}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

CompanyDetailsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    company: PropTypes.object
}

export default CompanyDetailsModal;
