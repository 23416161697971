import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from '../lib/API';

import { setWatchedStocksList, setCurrentWatchedStock } from '../slices/watchedStocksSlice';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NavBar, { tabs } from '../components/MainNavBar';
import WatchedStockFormModal from '../components/WatchedStockFormModal';


const WatchedStocksView = () => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showFormModal, setShowFormModal] = useState(false);
    const [displayList, setDisplayList] = useState([]);
    const watchedStocks = useSelector(state => state.watchedStocks.watchedStocksList);
    const watchedStock = useSelector(state => state.watchedStocks.currentWatchedStock);
    const token = useSelector(state => state.status.token);
    const dispatch = useDispatch();
    const fetchWatchedStocks = useCallback(() => {
        API.getWatchedStocks(token).then(response => {
            dispatch(setWatchedStocksList(response));
            setLoading(false);
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Unable to load watched stocks');
            setLoading(false);
        });
    }, [dispatch, token]);

    useEffect(() => {
        fetchWatchedStocks();
    }, [fetchWatchedStocks]);

    const handleClose = () => {
        setShowFormModal(false);
        dispatch(setCurrentWatchedStock(null));
        fetchWatchedStocks();
    };

    const filterWatchedStocks = val => {
        let listToDisplay = [];

        if (val) {
            listToDisplay = watchedStocks.filter(stock => stock.ticker.toLowerCase().includes(val.toLowerCase()));
        }

        setDisplayList(listToDisplay);
    };

    return (
        <>
            <NavBar tab={tabs.watchedStocks} />
            {loading ? (
                <div>
                    <h3>Loading watched stocks</h3>
                    <Spinner variant='primary' animation='border' />
                </div>
            ) : (
                <Container>
                    <h2 style={{ textAlign: 'center' }}>List of watched stocks</h2>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Button size="lg" onClick={() => { setShowFormModal(true) }}>New Watched Stock</Button>
                    {showFormModal || watchedStock !== null ? (
                        <WatchedStockFormModal show handleClose={handleClose} stock={watchedStock}/>
                    ): null}
                    <Form>
                        <Row className="mt-3">
                            <Col md={6}>
                                <Form.Control type="text" placeholder="Ticker filter" onChange={e => filterWatchedStocks(e.target.value)} />
                            </Col>
                        </Row>
                    </Form>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Ticker</th>
                                <th>Condition</th>
                                <th>Price</th>
                                <th>Email List</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(displayList.length ? displayList : watchedStocks).map((stock, index) => {
                                return (
                                    <tr key={index} onClick={() => { dispatch(setCurrentWatchedStock(stock)) }} style={{ cursor: 'pointer' }}>
                                        <td>{stock.ticker}</td>
                                        <td>{stock.comparisonInfo.comparison === 'less' ? 'Less than' : 'Greater than'}</td>
                                        <td>{stock.price}</td>
                                        <td>{stock.emailList}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Container>
            )}
        </>
    );
}

export default WatchedStocksView;
