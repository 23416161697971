import React from 'react';
import {Link} from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div className="text-center">
            <h1>404 Not found</h1>
            <p>
                {"Looks like you are looking for something that doesn't exist!"}
                <br/>
                <Link to="/">Go back to the main website.</Link>
            </p>
        </div>
    );
}

export default ErrorPage;