import { createSlice } from "@reduxjs/toolkit";

export const stocksReducer = createSlice({
    name: 'stocks',
    initialState: {
        currentValues: null,
    },
    reducers: {
        setCurrentValues: (state, action) => {
            state.currentValues = action.payload;
        },
    },
});

export const {setCurrentValues} = stocksReducer.actions;

export default stocksReducer.reducer;
