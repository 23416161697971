import { createSlice } from "@reduxjs/toolkit";

export const portfoliosReducer = createSlice({
    name: 'portfolios',
    initialState: {
        portfolios: [],
        activePortfolio: {},
    },
    reducers: {
        setPortfolios: (state, action) => {
            state.portfolios = action.payload;
        },
        setActivePortfolio: (state, action) => {
            state.activePortfolio = action.payload;
        }
    },
});

export const {setPortfolios, setActivePortfolio} = portfoliosReducer.actions;

export default portfoliosReducer.reducer;
