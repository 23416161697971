import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import API from '../lib/API';
import { useSelector } from 'react-redux';



const WatchedStockFormModal = (props) => {

    const [errorMessage, setErrorMessage] = useState('');
    const [ticker, setTicker] = useState(props.stock ? props.stock.ticker : '');
    const [price, setPrice] = useState(props.stock ? props.stock.price : '');
    const [comparison, setComparison] = useState(props.stock ? props.stock.comparisonInfo.comparison : 'less');
    const [emailList, setEmailList] = useState(props.stock ? props.stock.emailList : '');
    const token = useSelector(state => state.status.token);

    const submit = (event) => {
        event.preventDefault();

        if (isNaN(price)) {
            setErrorMessage('Price must be a number');
        }

        if (props.stock) {
            API.updateWatchedStock(token, props.stock.id, props.stock.ticker, Number(price).toFixed(2), emailList, { comparison }).then(response => {
                props.handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error updating watched stock');
            });
        } else {
            API.createWatchedStock(token, ticker, Number(price).toFixed(2), emailList, { comparison }).then(response => {
                props.handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error creating watched stock');
            });   
        }
    }

    const deleteWatchedStock = () => {
        API.deleteWatchedStock(token, props.stock.id).then(response => {
            props.handleClose();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error deleting watched stock');
        })
    }

    return (
        <Modal show={props.show} onHide={() => { props.handleClose() }} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.stock ? props.stock.ticker : 'New Watched Stock'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticker</Form.Label>
                            <Form.Control type="text" placeholder="Ticker" value={ticker} onChange={e => { setTicker(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comparison</Form.Label>
                            <Form.Select value={comparison} onChange={e => { setComparison(e.target.value) }}>
                                <option value="less">Less than</option>
                                <option value="greater">Greater than</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number" placeholder="Price" value={price} onChange={e => { setPrice(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email list</Form.Label>
                            <Form.Control type="text" placeholder="email list" value={emailList} onChange={e => { setEmailList(e.target.value) }} />
                        </Form.Group>
                        <>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            {props.stock ? (
                                <Button variant="danger" onClick={() => {deleteWatchedStock()}}>Delete</Button>
                            ) : null}
                        </>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

WatchedStockFormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    stock: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
}

export default WatchedStockFormModal;
