import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ErrorPage from './views/ErrorPage';
import LoginModal from './components/LoginModal';
import HomeView from './views/HomeView';
import WatchedStocksView from './views/WatchedStocksView';
import PortfolioView from './views/PortfolioView';
import SpendView from './views/SpendView';

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomeView/>} />
                    <Route path="/watch" element={<WatchedStocksView/>} />
                    <Route path="/portfolio" element={<PortfolioView/>} />
                    <Route path="/spend" element={<SpendView/>} />
                    <Route path="*" element={<ErrorPage/>} />
                </Routes>
            </BrowserRouter>
            <LoginModal />
        </>
    );
}

export default App;
