import axios from 'axios';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_PATCH = 'PATCH';
const METHOD_DELETE = 'DELETE';

const BASE_URL = 'https://api.caramel.business'; // 'http://127.0.0.1:8000';
const ERROR_MESSAGE = "We weren't able to reach the server, try again later";

function _request(method, path, token = '', data = {}) {
    return new Promise((resolve, reject) => {
        const queryParams = method === 'GET' ? new URLSearchParams(data).toString() : '';
        axios({
            headers: token ? {'Authorization': `Token ${token}`} : {},
            method: method,
            url: `${BASE_URL}/${path}${queryParams ? `?${queryParams}` : ''}`,
            data: method === 'GET' ? {} : data,
        }).then(axiosResponse => {
            resolve(axiosResponse.data);
        }).catch(error => {
            console.log(error);
            if (error.response) {
                reject(error.response.data.detail);
            } else if (error.request) {
                reject({
                    code: 500,
                    message: ERROR_MESSAGE,
                    error: error.request
                });
            } else {
                reject({
                    code: 500,
                    message: ERROR_MESSAGE,
                    error: error.message
                });
            }
        });
    });
};

const login = (username, password) => {
    return _request(METHOD_POST, 'api-auth/', '', {username, password});
}

const getCompanies = () => {
    return _request(METHOD_GET, 'stocks/companies/');
}

const getIPOs = () => {
    return _request(METHOD_GET, 'stocks/ipos/');
}

const getWatchedStocks = (token) => {
    return _request(METHOD_GET, 'stocks/watch/', token);
}

const createWatchedStock = (token, ticker, price, emailList, comparisonInfo) => {
    return _request(METHOD_POST, 'stocks/watch/', token, {ticker, price, emailList, comparisonInfo});
};

const updateWatchedStock = (token, pk, ticker, price, emailList, comparisonInfo) => {
    return _request(METHOD_PUT, `stocks/watch/${pk}/`, token, {ticker, price, emailList, comparisonInfo});
}

const deleteWatchedStock = (token, pk) => {
    return _request(METHOD_DELETE, `stocks/watch/${pk}/`, token);
}

const getStockPortfolios = (token) => {
    return _request(METHOD_GET, 'stocks/portfolio/', token);
}

const createStockPortfolio = (token, userID, name, participants) => {
    return _request(METHOD_POST, 'stocks/portfolio/', token, {userID, name, participants});
}

const updateStockPortfolio = (token, pk, userID, name, participants) => {
    return _request(METHOD_PUT, `stocks/portfolio/${pk}/`, token, {userID, name, participants});
}

const deleteStockPortfolio = (token, pk) => {
    return _request(METHOD_DELETE, `stocks/portfolio/${pk}/`, token);
}

const getStockTransactions = (token) => {
    return _request(METHOD_GET, 'stocks/transaction/', token);
}

const createStockTransaction = (token, userID, ticker, price, quantity, date, type, portfolio, note) => {
    return _request(METHOD_POST, 'stocks/transaction/', token, {userID, ticker, price, quantity, date, type, portfolio, note});
}

const updateStockTransaction = (token, pk, userID, ticker, price, quantity, date, portfolio, note) => {
    return _request(METHOD_PATCH, `stocks/transaction/${pk}/`, token, {userID, ticker, price, quantity, date, portfolio, note});
}

const deleteStockTransaction = (token, pk) => {
    return _request(METHOD_DELETE, `stocks/transaction/${pk}/`, token);
}

const getStockCurrentValues = (token) => {
    return _request(METHOD_GET, 'stocks/currentvalues/', token);
}

const getPersonalTransactions = (token) => {
    return _request(METHOD_GET, 'pfinance/transactions/', token);
}

const importPersonalTransactions = (token, defaultTags, jsonInput) => {
    return _request(METHOD_POST, 'pfinance/transactions/import/', token, {defaultTags, jsonInput});
}

const deletePersonalTransaction = (token, pk) => {
    return _request(METHOD_DELETE, `pfinance/transactions/${pk}/`, token);
}

const getUserTags = (token) => {
    return _request(METHOD_GET, 'pfinance/tags/', token);
}

const deleteUserTag = (token, pk) => {
    return _request(METHOD_DELETE, `pfinance/tags/${pk}/`, token);
}

const API = {
    login,
    getIPOs,
    getCompanies,
    getWatchedStocks,
    createWatchedStock,
    updateWatchedStock,
    deleteWatchedStock,
    getStockPortfolios,
    createStockPortfolio,
    updateStockPortfolio,
    deleteStockPortfolio,
    getStockTransactions,
    createStockTransaction,
    updateStockTransaction,
    deleteStockTransaction,
    getStockCurrentValues,
    getPersonalTransactions,
    importPersonalTransactions,
    deletePersonalTransaction,
    getUserTags,
    deleteUserTag,
};

export default API;