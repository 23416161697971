import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import NavBar, { tabs } from '../components/MainNavBar';
import ConfirmationModal from '../components/ConfirmationModal';

import { setPersonalTransactions } from '../slices/transactionsSlice';
import ImportPersonalTransactionsModal from '../components/ImportPersonalTransactionsModal';

import API from '../lib/API';

const SpendView = () => {
    const dispatch = useDispatch();
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showImportTransactionsModal, setShowImportTransactionsModal] = useState(false);
    const [activeTransactionId, setActiveTransactionId] = useState(0);
    const transactions = useSelector(state => state.transactions.personalTransactions);
    const token = useSelector(state => state.status.token);

    const fetchPersonalTransactions = useCallback(() => {
            API.getPersonalTransactions(token).then(response => {
                dispatch(setPersonalTransactions(response));
                setLoadingTransactions(false);
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Unable to load transactions');
                setLoadingTransactions(false);
            });
    }, [dispatch, token]);

    useEffect(() => {
        fetchPersonalTransactions();
    }, [fetchPersonalTransactions]);

    const handleDelete = (transactionID) => {
        setActiveTransactionId(transactionID);
        setShowConfirmationModal(true);
    }

    const handleConfirmationModalClose = () => {
        setActiveTransactionId(0);
        setShowConfirmationModal(false);
    }

    const handleConfirmationModalConfirm = () => {
        API.deletePersonalTransaction(token, activeTransactionId).then(response => {
            handleConfirmationModalClose();
            fetchPersonalTransactions();
        }).catch(error => {
            setErrorMessage(error.message);
        });
    }

    const importTransactions = () => {
        setShowImportTransactionsModal(true);
    }

    const onImportComplete = () => {
        setShowImportTransactionsModal(false);
        fetchPersonalTransactions();
    }

    const handleImportTransactionsModalClose = () => {
        setShowImportTransactionsModal(false);
    }

    return (
        <>
            <NavBar tab={tabs.spend} />
            <ConfirmationModal 
                show={showConfirmationModal} 
                title="Delete transaction?"
                message="Are you sure you want to delete this transaction?"
                handleConfirm={handleConfirmationModalConfirm}
                handleClose={handleConfirmationModalClose}
            />
            <ImportPersonalTransactionsModal
                show={showImportTransactionsModal}
                handleClose={handleImportTransactionsModalClose}
                handleImportComplete={onImportComplete}
            />
            {loadingTransactions ? (
                <div>
                    <h3>Loading transactions</h3>
                    <Spinner variant='primary' animation='border' />
                </div>
            ) : (
                <Container>
                    <h2>Personal Spend</h2>
                    <Button variant='primary' onClick={importTransactions} size='lg'>Import Transactions</Button>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Merchant</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Tags</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map(transaction => (
                                <tr>
                                    <td>{transaction.date}</td>
                                    <td>{transaction.merchant}</td>
                                    <td>{transaction.amount / 100}</td>
                                    <td>{transaction.currency}</td>
                                    <td>{transaction.tags}</td>
                                    <td>
                                        <Button variant='danger' onClick={() => handleDelete(transaction.transactionID)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            )}
        </>
    );
}

export default SpendView;
