import {createSlice} from "@reduxjs/toolkit";

export const watchedStocksReducer = createSlice({
    name: 'watchedStocks',
    initialState: {
        watchedStocksList: [],
        currentWatchedStock: null,
    },
    reducers: {
        setWatchedStocksList: (state, action) => {
            state.watchedStocksList = action.payload;
        },
        setCurrentWatchedStock: (state, action) => {
            state.currentWatchedStock = action.payload;
        }
    }
});

export const {setWatchedStocksList, setCurrentWatchedStock} = watchedStocksReducer.actions;

export default watchedStocksReducer.reducer;
