import React from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

const DEFAULT_POSITION = {
    totalInput: 0,
    currentValue: 0,
    totalSales: 0,
    quantity: 0,
}

const PortfolioSection = (props) => {
    const getPortfolio = () => {
        let p = { ...props.portfolio };
        p.totalInput = 0;
        p.currentValue = 0;
        p.totalSales = 0;
        p.positions = {};

        // Get total portfolio input
        // Get total portfolio value
        // Get total portfolio sales value
        console.log('current', props.currentValues);
        // Get portfolio transactions with gains on each
        for (let i = 0; i < props.transactions.length; i++) {
            const transaction = props.transactions[i];
            let position = p.positions[transaction.ticker] ? p.positions[transaction.ticker] : Object.assign({}, DEFAULT_POSITION);
            const transactionValue = (transaction.price * transaction.quantity);
            const currentValue = props.currentValues[transaction.ticker] ? props.currentValues[transaction.ticker] * transaction.quantity : 0;
            position.quantity = transaction.type === 'BUY' ? position.quantity + transaction.quantity : position.quantity - transaction.quantity;

            if (transaction.type === 'BUY') {
                p.totalInput += transactionValue;
                position.totalInput += transactionValue;
                position.currentValue += currentValue;
                p.currentValue += currentValue;
            } else {
                p.totalSales += transactionValue;
                position.totalSales += transactionValue;
                position.currentValue -= currentValue;
                p.currentValue -= currentValue;
            }

            p.positions[transaction.ticker] = position;
        }

        return p;
    }

    const formatNumber = (num) => {
        return Number(num).toFixed(2);
    }

    const portfolio = getPortfolio();
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>Total Cost</Card.Title>
                                <Card.Title>{formatNumber(portfolio.totalInput)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>Current Value</Card.Title>
                                <Card.Title>{formatNumber(portfolio.currentValue)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>Total Sales</Card.Title>
                                <Card.Title>{formatNumber(portfolio.totalSales)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>Net Gain</Card.Title>
                                <Card.Title>{formatNumber(portfolio.currentValue + portfolio.totalSales - portfolio.totalInput)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <h2>Return by asset</h2>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Ticker</th>
                            <th>Quantity</th>
                            <th>Total input</th>
                            <th>Current value</th>
                            <th>Total sales</th>
                            <th>Net Gain</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(portfolio.positions).map(ticker => {
                            const pos = portfolio.positions[ticker];

                            return (
                                <tr key={ticker}>
                                    <td>{ticker}</td>
                                    <td>{pos.quantity}</td>
                                    <td>{formatNumber(pos.totalInput)}</td>
                                    <td>{formatNumber(pos.currentValue)}</td>
                                    <td>{formatNumber(pos.totalSales)}</td>
                                    <td>{formatNumber(pos.currentValue + pos.totalSales - pos.totalInput)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
            <Container>
                <h2>All transactions</h2>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Ticker</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.transactions.map(t => (
                            <tr key={t.id} onClick={() => { props.setOpenTransaction(t) }} style={{ cursor: 'pointer' }}>
                                <td>{t.type}</td>
                                <td>{t.date}</td>
                                <td>{t.ticker}</td>
                                <td>{t.price}</td>
                                <td>{t.quantity}</td>
                                <td>{formatNumber(t.price * t.quantity)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </>
    );
};

PortfolioSection.propTypes = {
    transactions: PropTypes.array.isRequired,
    portfolio: PropTypes.object.isRequired,
    currentValues: PropTypes.object.isRequired,
    setOpenTransaction: PropTypes.func.isRequired,
}

export default PortfolioSection;
